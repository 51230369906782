// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h_9x2LOgrrrTsv4SROpO::-webkit-outer-spin-button,.h_9x2LOgrrrTsv4SROpO::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}.h_9x2LOgrrrTsv4SROpO[type=number]{-moz-appearance:textfield}.MWSaI6Bp5mKdTqe7HhaB{position:absolute;top:12px;right:0;display:flex;gap:8px}.jNRc27KnE2NkhkC0DXiv{position:absolute;left:0;top:0}.P3KJ5a4dnhJP_ETvboOJ{pointer-events:none;opacity:.5}.ui1R9nxW3hep53O1_nPI{display:none}.VxZdeCqsLTiRomE2f2Cz{position:absolute;top:-24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numberInput": "h_9x2LOgrrrTsv4SROpO",
	"suffix": "MWSaI6Bp5mKdTqe7HhaB",
	"selectLabel": "jNRc27KnE2NkhkC0DXiv",
	"disabled": "P3KJ5a4dnhJP_ETvboOJ",
	"hidden": "ui1R9nxW3hep53O1_nPI",
	"onTop": "VxZdeCqsLTiRomE2f2Cz"
};
export default ___CSS_LOADER_EXPORT___;
