import styles from './Inputs.module.scss';

type InputProps = {
    name: string,
    label: string,
    disabled?: boolean,
    readonly?: boolean,
    value?: string,
    className?: string,
    maxLength?: number,
    error?: string;
    children?: React.ReactNode;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
    type?: string;
};


export const InputText = ({
    name, 
    label, 
    disabled=false, 
    readonly=false,
    value,
    className,
    maxLength,
    error,
    children,
    ...props
}: InputProps) => {


    return (<div className="form-text">
    <input
        type="text"
        name={name}
        placeholder={label}
        disabled={disabled}
        readOnly={readonly ? readonly : false}
        value={value}
        className={className}
        maxLength={maxLength}
        {...props}
    />
    <label htmlFor={name}>
        <span>{label}</span>
        {
            maxLength && <span>{length}/{maxLength}</span>
        }
    </label>
    {children && <div className={styles.suffix}>{children}</div>}
    {error && <span className="form-error">{error}</span>}
</div>)
}