import styles from "./app.module.scss";
import { popup, ButtonResult, ButtonType, PopupSize, FinancePopUpBody, useMachineCartItemUpdate, useMachineCartChange, updateMachineCartItem, updateMachineCart } from "ui";
import { UpdateFinanceOptions } from "./types";
import { MachinePaymentMode } from "ui/src/types";

const translations = (window as any).app.preloadState.translation;
const pdpData = window.app.preloadState.machinePdp;
const financingPopUpData = window.app.preloadState.financingPopUp;
let paymentInCart: MachinePaymentMode | undefined;

function App() {
    useMachineCartChange(cart => {
        paymentInCart = cart.lineItems.find(m => m.code == pdpData.itemNumber)?.paymentMode;
    });

    const financeOptionsPopup = async () => {
        let financeOptions: UpdateFinanceOptions | null = null;
        let buttons = [];
        switch (paymentInCart) {
            case MachinePaymentMode.Direct:
                buttons = [
                    { label: translations["financeOptions.addFinancing"], result: ButtonResult.Ok, type: ButtonType.Primary },
                    { label: translations["financeOptions.cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined }
                ]
                break;
            case MachinePaymentMode.Monthly:
                buttons = [
                    { label: translations["financeOptions.update"], result: ButtonResult.Ok, type: ButtonType.Primary },
                    { label: translations["financeOptions.remove"], result: ButtonResult.No, type: ButtonType.Outlined },
                    { label: translations["financeOptions.cancel"], result: ButtonResult.Cancel, type: ButtonType.Link },
                ]
                break;
            case undefined:
                buttons = [
                    { label: translations["financeOptions.addToCart"], result: ButtonResult.Ok, type: ButtonType.Primary },
                    { label: translations["financeOptions.cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined }
                ]
                break;
        }
        const popUpResult = await popup(
            translations["financeOptions.header"],
            <FinancePopUpBody serialNumber={pdpData.itemNumber ?? ""} onChange={(options) => financeOptions = options }/>,
            buttons,
            PopupSize.ExtraVertical,
            undefined,
            {
                content: <div className={styles.info}>
                    <div>
                        <span>{translations["financeOptions.email"]}: {financingPopUpData.financingPopUpEmailAddress}</span>
                    </div>
                    <div>
                        <span>{translations["financeOptions.phone"]}: {financingPopUpData.financingPopUpPhoneNumber}</span>
                    </div>
                </div>,
                reverse: true
            }
        );
        
        if (popUpResult === ButtonResult.Ok) {
            
            const updateResponse = await fetch(`/api/machinesales/cart/items/update-finance-options`, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": window.app.preloadState.currentLanguage
                },
                body: JSON.stringify(financeOptions),
                method: "POST"
            });

            if (!updateResponse.ok) {
                console.error(await updateResponse.text());
            }
            if (paymentInCart === undefined) {
                await updateMachineCartItem(pdpData.itemNumber ?? "", 1);
            } else {
                await updateMachineCart();
            }
        } else if (popUpResult === ButtonResult.No) {
            const updateResponse = await fetch(`/api/machinesales/cart/items/remove-finance-options`, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": window.app.preloadState.currentLanguage
                },
                body: JSON.stringify({ code: pdpData.itemNumber ?? "" }),
                method: "DELETE"
            });

            if (!updateResponse.ok) {
                console.error(await updateResponse.text());
            }
            await updateMachineCart();
        }
    }

    return <a onClick={financeOptionsPopup} className="btn btn--outlined w-100">{translations["machinePdp.financeButtonLabel"]}</a>
}

export default App;
